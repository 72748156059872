import React, { Fragment } from 'react';

import cx from 'classnames';
import Styles from '../../assets/styles/funnel.module.css'
import hashchingMagnifyingGlass from '../../assets/images/hashchingMagnifyingGlass.png'
import validator from 'validator';

import Axios from "axios";

import Cookies from 'universal-cookie';

import { graphql, StaticQuery } from 'gatsby';

import { log, oWindow, host, cookieRead } from '../../helpers/commons'

import { calculateMontlyRepayments, customSearch, customSearchMortgageLoanAmount, customSearchLvr } from '../../helpers/dealFilters';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// library.add(faCheck)

class FunnelSaveYourProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: (cookieRead('SaveEmail') != undefined) ? cookieRead('SaveEmail') : '',
                first_name: (cookieRead('SaveName') != undefined) ? cookieRead('SaveName') : ''
            },
            errors: {},
            data: [],
            isLoading: false
        }

        this.validateForm = this.validateForm.bind(this);

    }

    validateForm(event, validation = 'all', value = '') {
        log('validate form')
        if (event) {
            event.preventDefault();
        }

        let formData = this.state.formData;
        let stateData = this.state;
        let email = '';
        let name = '';

        if (validation == 'all' || validation == 'email') {

            if (validation != 'all') {
                email = value;
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'email': value }
                    }
                })
            } else {
                email = formData.email;
                value = formData.email;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.email = "Please enter your email.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isEmail(value)) {
                errors.email = "Please enter a valid email";
            } else {
                let errors = this.state.errors;
                delete errors.email;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'first_name') {

            if (validation != 'all') {
                name = value;
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'first_name': value }
                    }
                })
            } else {
                name = formData.first_name;
                value = formData.first_name;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.first_name = "Please enter your name.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.first_name;
                this.setState({
                    errors: errors
                })
            }
        }


        if (validation == 'all' && validator.isEmail(email) && !!name) {
            let errors = this.state.errors;
            let funnelData = this.props.funnelData;
            funnelData.bookAppointment = { name: name };
            log("validate all called..")
            this.setState({
                isLoading: true
            })
            Axios.post(host + '/gatsby-api/save-for-later', {
                email: email,
                type: 'funnel',
                data_info: JSON.stringify(funnelData)
            }).then((response) => {
                this.setState({
                    isLoading: false
                })
                if (response.data.status == '100') {
                    console.log("saving part is successful")
                    console.log(this.props);
                    this.props.handleSaveYourProgress(email, name);
                }
                else {
                    errors.email = "Unable to save your details";
                    this.setState({
                        errors: errors
                    })
                }
            }).catch((error) => {
                errors.email = "Unable to save your details";
                this.setState({
                    isLoading: false,
                    errors: errors
                })
            });
        } else {
            log("form is not valid..");
        }
    }
    handleKeyPress(target) {
        if(target.charCode==13){
          document.getElementById('saveprogressbtn').click();   
        } 
      }

backBtnHandler() {

        if (typeof window != 'undefined') {
            window.history.back();           
           
        }
        return false;
    }
    componentDidMount() {
        this.setState({
            data: this.props.data.allHashchingDeal.edges
        });
        this.funnelFilterData();
    }

    funnelFilterData() {
        let funnelData = this.props.funnelData;
        if ((typeof (funnelData) !== 'undefined' || funnelData != null)) {
            if (funnelData.aboutProperty) {
                if (funnelData.aboutProperty.loan_type) {
                    let valueLoanType = funnelData.aboutProperty.loan_type;
                    if (valueLoanType == 'new-loan') {
                        this.setState({
                            loanType: 2,
                            currentPage: 1
                        });
                    }
                    else if (valueLoanType == "refinance") {
                        this.setState({
                            loanType: 3,
                            currentPage: 1
                        });
                    }
                }
                if (funnelData.aboutProperty.investmentType) {
                    let valueInvestmentType = funnelData.aboutProperty.investmentType;
                    if (valueInvestmentType == "to-live") {
                        this.setState({
                            propertyTyp: "5,3,2,6",
                            currentPage: 1
                        })
                    }
                    else if (valueInvestmentType == "to-invest") {
                        this.setState({
                            propertyTyp: "1",
                            currentPage: 1
                        })
                    }
                }
                if (funnelData.aboutProperty.amount) {
                    let valueAmount = funnelData.aboutProperty.amount;
                    this.setState({
                        mortgageLoanAmount: valueAmount,
                        currentPage: 1
                    })
                }
                if (funnelData.aboutProperty.estimated) {
                    let valueEstimated = funnelData.aboutProperty.estimated;
                    this.setState({
                        propertyValue: valueEstimated,
                        currentPage: 1
                    })
                }
            }
        }
    }

    render() {

        // this.state.data.map((deal) => deal.node.monthly_repayments = calculateMontlyRepayments(deal.node.interest_rate, this.state.loanTerm, this.state.mortgageLoanAmount))
        let data = this.state.data;

        if (this.state.loanType !== '') {
            data = data.filter(({ node }) => node.deal_type_id.search(this.state.loanType) >= 0)
        }

        if (this.state.propertyTyp !== '') {
            data = data.filter(({ node }) => customSearch(node.deal_type_id, this.state.propertyTyp) >= 0)
        }

        if (this.state.mortgageLoanAmount !== '') {
            data = data.filter(({ node }) => customSearchMortgageLoanAmount(node.minimum_borrow_amount, this.state.mortgageLoanAmount) >= 0)
        }

        if (this.state.mortgageLoanAmount !== '' && this.state.propertyValue !== '') {
            var lvr = (parseInt(this.state.mortgageLoanAmount) / parseInt(this.state.propertyValue)) * 100
            data = data.filter(({ node }) => customSearchLvr(node.loan_to_value_ratio, lvr) >= 0)
        }

        //let dealCount = data.length;
        let dealCount = 0;

        console.log("====>>>>", data);

        return (
            <Fragment>
                <section className={cx(Styles.getstartedStepsPage)}>





                <section className={cx('bottom-sticky-buttons-sectionv2 funnelBottomStickyButtonsSection', Styles.funnelBottomStickyButtonsSection)}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={cx("col-xl-12 col-lg-12 mr-auto ml-auto", "bottom-sticky-buttons")}>


                <div className={"sticky-buttons-main"}>
                                <div className={cx("row")}>
                                    <div className={cx("col-md-2 col-lg-4", "sticky-buttons", "btn-left")}>
                                        <button className={cx("btn", "btn-left-link")} onClick={this.backBtnHandler}>
                                            {/* <i className={cx("fa", "fa-chevron-left")} aria-hidden="true"></i> */}
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-40.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M17.7235786,23.7204755 L18.444132,22.9984485 C18.8404319,22.6013317 19.1719795,22.2764214 19.1809189,22.2764214 C19.1898453,22.2764214 20.2156076,23.2967371 21.4603719,24.5438098 L23.7235786,26.8111982 L25.9867852,24.5438098 C27.2315495,23.2967371 28.2572988,22.2764214 28.2662253,22.2764214 C28.2751647,22.2764214 28.6067252,22.6013317 29.0030251,22.9984485 L29.7235786,23.7204755 L26.7276402,26.722027 C25.0798779,28.3728882 23.7280483,29.7235786 23.7235786,29.7235786 C23.7191089,29.7235786 22.3672792,28.3728882 20.719517,26.722027 L17.7235786,23.7204755 Z"
                                                                id="Fill-1-Copy-4" transform="translate(23.723579, 26.000000) rotate(-270.000000) translate(-23.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className={"btn-txt"}>Back</span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                            </div>
                            </div></div></section>


                          {/* <section className={cx(Styles.loandealProcessSection)}>
                <div className={cx('container')}>
                    <div className={cx('row')}>
                       
                        <div className={cx('col-12', Styles.loandealProcessInnr)}>
                            <div className={cx(Styles.loandealProcess)}>
                                <div className={cx('d-flex', 'justify-content-center', Styles.bsWizard)}>
                                    
                                        <div className={cx(Styles.bsWizardStep, step.className)}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-12', Styles.stepCount)}>
                                                    <div className={cx(Styles.progress)}>
                                                    </div>
                                                    <span className={cx(Styles.progressJoint)}></span>
                                                    <div className={cx(Styles.bsWizardDot)}>
                                                        <span className={cx(Styles.number)}>
                                                            <small className={cx(Styles.count)}>1</small>
                                                        </span>
                                                        <span className={cx(Styles.tick)}>
                                                            <small className={cx(Styles.chkicon)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
    <path fill="#17D2FD" fill-rule="evenodd" d="M12.098 2.132L5.022 9.423a.5.5 0 0 1-.718 0L.141 5.136a.5.5 0 0 1 0-.697l1.246-1.284a.5.5 0 0 1 .718 0l2.198 2.264a.5.5 0 0 0 .717 0L10.134.152a.5.5 0 0 1 .717 0l1.247 1.284a.5.5 0 0 1 0 .696z"/>
</svg>

                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                                    <div className={cx(Styles.bsWizardStepnum)}>testt</div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}  

                    <section className={cx(Styles.loandealStepSection, Styles.aboutInfoSection, Styles.pageBgcolor)}>
                        <div className={'container'}>
                            <div className={'row'}>







                                <div className={cx('col-lg-6  mr-auto ml-auto', Styles.othrstepSection)}>
                                
                                    <div className={cx(Styles.entrEmailSection)}>
                                    <div className={cx(Styles.magnifyImgMain)}>
                                        <img className={cx(Styles.magnifyImg)} src={hashchingMagnifyingGlass} alt="hashchingMagnifyingGlass" />
                                    </div>
                                    <div className={cx('card', Styles.card)}>
                                        <div className={cx('card-body', Styles.cardBody)}>
                                        <div className={cx(Styles.sectioncontnt)}>
                                            <h3 className={cx('text-center', Styles.sectionTitle)}>Provide information about the main applicant of the loan</h3>

                                            <div className={cx('form-group', Styles.formGroup)}>
                                                <label>Enter your name</label>
                                                <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} value={this.state.formData.first_name} placeholder="Name" onChange={((event) => {
                                                    this.validateForm(event, 'first_name', event.target.value);
                                                })} />
                                                <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.first_name) ? 'block' : 'none' }}>
                                                    {this.state.errors.first_name}
                                                </div>
                                            </div>

                                            <div className={cx('form-group', Styles.formGroup)}>
                                                <label>Enter your email</label>
                                                <input type="text" value={this.state.formData.email} onKeyPress={this.handleKeyPress} className={cx('form-control', Styles.formControl)} placeholder="example@example.com" onChange={((event) => {
                                                    this.validateForm(event, 'email', event.target.value);
                                                })} />
                                                <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.email) ? 'block' : 'none' }}>
                                                    {this.state.errors.email}
                                                </div>
                                            </div>
                                            {dealCount >= 1 &&
                                                (<h3 className={cx('text-center', Styles.savebtnBottomTxt)}>We have found {dealCount > 1 ? dealCount + ' deals' : dealCount + ' deal'} for you!</h3>)}
                                            <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                <a className={cx('btn', Styles.blueBtn, Styles.btn, (this.state.isLoading == true) ? 'loading' : '')} id="saveprogressbtn" href="javascript:void(0);" onClick={this.validateForm}>
                                                    {(() => {
                                                        if (dealCount > 0) {
                                                            if(dealCount == 1) {
                                                                return `Create Appointment`;
                                                            } 
                                                            return `Create Appointment`;
                                                        } else {
                                                            return 'Create Appointment'
                                                        }
                                                    })()}

                                                </a>
                                            </div>
                                            <hr />
                                            <div className={cx(Styles.sectionOthrDescrption)}>

                                                <p>Connecting with a HFG Marketplace broker is free of charge and obligation-free.</p>                                                
                                                <p>Your data is safe with us. Don’t worry, no spam or selling it to other affiliates</p>
                                                <p>We may send you relevant information on market trends, news and rates updates so you can make informed decisions. Access top rates and products from over 100+ lenders and growing</p>
                                            </div>
                                            {/* <a className={cx(Styles.skipstpLink)} href="javascript:void(0);" onClick={() => {
                                                this.props.skipBtnHandler('save-your-progress')
                                            }} >Skip this step</a> */}
                                        </div>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Fragment>

        )
    }
}

export default (props) => (
    <StaticQuery
        query={
            graphql`
            query {
                allHashchingDeal {
                    edges {
                        node {
                            id
                            deal_id
                            deal_type_id
                            slug
                            interest_rate
                            interest_type
                            repayment_type
                            comparison_interest_rate
                            loan_to_value_ratio
                            application_fees
                            ongoinFees
                            ongoing_fee_type
                            minimum_borrow_amount
                            meta_description
                            seo_title
                            focus_keyword
                            facebook_title
                            facebook_description
                            canonical_url
                            monthly_repayments
                            allNewFeatures
                        }
                    }
                }
            }
        `
        }
        render={(data) => (
            <FunnelSaveYourProgress data={data} {...props} />
        )}
    />
)